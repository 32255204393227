import logo from './logo.png'
import promo from './image_1.jpg'
import promo2 from './promo2.png'
import cart from './item-1.jpg'
// eslint-disable-next-line import/no-anonymous-default-export
export default {
	logo,
	promo,
	promo2,
	cart,
}
